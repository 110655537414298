<template>
	<div class="row">
		<div class="col-md-12 mb-3">
			<a href="#" @click.prevent="showObs(index)">Ver passo a passo</a>
		</div>
		<div class="col-md-12 opacity" :class="`opacity${index}`">
			<div class="row">
				<div class="col-md-12 mb-3">
					<div class="row">
						<div class="col-md-4">
							<div class="box_image position-relative">
								<img :src="instruct.image" @error="errorImage2" />
								<input
									class="btnAlteraFoto position-absolute"
									type="file"
									@change="onChoseFileInstruct()"
									id="file"
									accept="image/x-png, image/gif, image/jpeg"
									placeholder="Alterar"
								/>
							</div>
						</div>
						<div class="col-md-8 pl-0">
							<div class="form__group field">
								<input
									type="input"
									class="form__field"
									placeholder="Titulo"
									name="Titulo"
									id="Titulo"
									v-model="instruct.title"
								/>
								<label for="Titulo" class="form__label">Titulo</label>
							</div>
							<textarea class="w-100 mb-0 px-2 py-2" v-model="instruct.description"></textarea>
						</div>
						<div class="col-md-4 ml-auto text-right">
							<a
								class="mb-0 p_destaque"
								@click.prevent="selectDestaqueNew()"
								:class="instruct.spotlight ? 'p_destaque_active' : 'p_destaque'"
							>
								Destaque
								<i class="fas fa-star"></i>
							</a>
						</div>
						<div class="col-md-12 my-3">
							<button class="btn_file btn_default" @click.prevent="convertArray()">
								Adicionar
								<i class="fas fa-plus-circle ml-1"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-12 mb-3" v-for="(item,i) in instructions" :key="i">
					<div class="row">
						<div class="col-md-4">
							<div class="box_image position-relative">
								<img
									:src=" item.image.includes('data')  ? item.image : getImg(item.image)"
									alt
									@error="errorImage2"
								/>
								<input
									class="btnAlteraFoto position-absolute"
									type="file"
									@change="onChoseFile($event ,i)"
									id="file"
									accept="image/x-png, image/gif, image/jpeg"
									placeholder="Alterar"
								/>
							</div>
						</div>
						<div class="col-md-8 pl-0">
							<div class="form__group field">
								<input
									type="input"
									class="form__field"
									placeholder="Titulo"
									name="Titulo"
									id="Titulo"
									:value="item.title"
								/>
								<label for="Titulo" class="form__label">Titulo</label>
							</div>
							<textarea class="w-100 mb-0 px-2 py-2" :value="item.description"></textarea>
						</div>
						<div class="col-md-4 ml-auto text-right">
							<a
								class="mb-0 p_destaque"
								@click.prevent="selectDestaque(i)"
								:class="item.spotlight ? 'p_destaque_active' : 'p_destaque'"
							>
								Destaque
								<i class="fas fa-star"></i>
							</a>
						</div>
						<div class="col-md-12 my-3">
							<button class="btn_default_excluir btn_default" @click.prevent="exclude(item)">
								Excluir
								<i class="fas fa-trash-alt ml-1"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["instructions", "index"],
		data() {
			return {
				instruct: {
					image: "",
					title: "",
					description: "",
					spotlight: false,
					sequence: this.instructions.length + 1
				}
			};
		},
		mounted() {},
		methods: {
			showObs(index) {
				var show;

				if ($(`.opacity${index}`).is(":visible")) {
					show = "slideUp";
				} else {
					show = "slideDown";
				}

				$(`.opacity${index}`).velocity(show, {
					display: `${show == "slideUp" ? "none" : "flex"}`,
					duration: 500
				});
			},
			onChoseFileInstruct() {
				// Reference to the DOM input element
				var input = event.target;

				// Ensure that you have a file before attempting to read it
				if (input.files && input.files[0]) {
					if (Math.round(input.files[0].size / 1024) >= 1000) {
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i> Ooops, Arquivo maior que 1MB! Por favor ultilize um compressor ou selecione outro arquivo! \r Tamanho: ${Math.round(
								input.files[0].size / 1024
							)}KB`
						});

						$("#file").val("");
						return false;
					}
					// create a new FileReader to read this image and convert to base64 format
					var reader = new FileReader();
					// Define a callback function to run, when FileReader finishes its job
					reader.onload = e => {
						// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
						// Read image as base64 and set to imageData//
						this.instruct.image = e.target.result;
					};
					// Start the reader job - read file as a data url (base64 format)
					reader.readAsDataURL(input.files[0]);
				}
			},
			onChoseFile(event, i) {
				// Reference to the DOM input element
				var input = event.target;

				// Ensure that you have a file before attempting to read it
				if (input.files && input.files[0]) {
					if (Math.round(input.files[0].size / 1024) >= 1000) {
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i> Ooops, Arquivo maior que 1MB! Por favor ultilize um compressor ou selecione outro arquivo! \r Tamanho: ${Math.round(
								input.files[0].size / 1024
							)}KB`
						});

						$("#file").val("");
						return false;
					}
					// create a new FileReader to read this image and convert to base64 format
					var reader = new FileReader();
					// Define a callback function to run, when FileReader finishes its job
					reader.onload = e => {
						// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
						// Read image as base64 and set to imageData//
						this.instructions[i].image = e.target.result;
					};
					// Start the reader job - read file as a data url (base64 format)
					reader.readAsDataURL(input.files[0]);
				}
			},
			selectDestaqueNew() {
				if (this.instruct.spotlight == true) {
					this.instruct.spotlight = false;
				} else {
					this.instruct.spotlight = true;
				}
			},
			selectDestaque(i) {
				if (this.instructions[i].spotlight == true) {
					this.instructions[i].spotlight = false;
				} else {
					this.instructions[i].spotlight = true;
				}
			},
			exclude(item) {
				var currentProductIndex = this.instructions.findIndex(
					e => e.sequence == item.sequence
				);
				if (currentProductIndex > -1) {
					this.instructions.splice(currentProductIndex, 1);
					this.$notify({
						group: "erros",
						type: "sucess",
						text: `<i class="icon ion-close-circled"></i>Passo excluido`
					});
				}
			},
			convertArray() {
				if (this.instruct.title != "" && this.instruct.description) {
					this.$notify({
						group: "erros",
						type: "sucess",
						text: `<i class="icon ion-close-circled"></i>Passo Adicionado`
					});
					var array = this.instructions;
					array.push(this.instruct);
					this.$emit("update:instructions", array);
					this.instruct = {
						image: "",
						title: "",
						description: "",
						spotlight: false,
						sequence: this.instructions.length + 1
					};
				}
			}
		}
	};
</script>

<style scoped>
a {
	color: #2474b2;
}
a:hover {
	color: #66a0d0;
}
.box_image {
	width: 100%;
	overflow: hidden;
	border-radius: 20px;
	height: 100px;
}
.box_image img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}
.opacity {
	display: none;
}
textarea {
	border: 1px solid #e3e3e3;
	height: 50px;
	font-size: 12px;
	color: #495057;
	font-weight: 400;
	outline: none;
}

.form__group {
	padding: 7px;
	margin-top: 0;
	width: 100%;
}

.form__field {
	font-family: inherit;
	width: 100%;
	border: 0;
	border-bottom: 2px solid #f4f4f4;
	outline: 0;
	font-size: 10px !important;
	color: #495057;
	padding: 7px 0;
	background: transparent;
	transition: border-color 0.2s;
}
.form__field::placeholder {
	color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
	font-size: 14px;
	cursor: text;
	top: 20px;
}

.form__label {
	position: absolute;
	top: 0;
	display: block;
	transition: 0.2s;
	font-size: 12px !important;
	color: #2474b2;
}

.form__field:focus {
	padding-bottom: 6px;
	font-weight: 700;
	border-width: 3px;
	border-image: linear-gradient(to right, #f4ab7b, #e0a638);
	border-image-slice: 1;
}
.form__field:focus ~ .form__label {
	position: absolute;
	top: 0;
	display: block;
	transition: 0.2s;
	font-size: 1rem;
	color: #e0a638;
	font-weight: 700;
}
.failed.form__field ~ .form__label {
	position: absolute;
	top: 0;
	display: block;
	transition: 0.2s;
	font-size: 1rem;
	color: #dc3545 !important;
	font-weight: 700;
}
.p_destaque {
	color: #e3e3e3;
	font-size: 12px;
	font-weight: 700;
	display: block;
	transition: all 0.2s linear;
	cursor: pointer;
}
.p_destaque:hover {
	color: #e0a638;
}
.p_destaque_active {
	color: #e0a638 !important;
	transition: all 0.2s linear;
}
.box_image::after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	background-color: black;
	height: 100%;
	opacity: 0;
	transition: all 0.3s ease-in-out;
}
.box_image:hover::after {
	contain: "";
	opacity: 0.8;
}

.btnAlteraFoto::-webkit-file-upload-button {
	visibility: hidden;
}
.btnAlteraFoto::after {
	content: "Alterar foto";
	color: #fff;
	outline: none;
	position: absolute;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 11px;
	opacity: 0;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s ease-in-out;
}
.box_image:hover .btnAlteraFoto::after {
	opacity: 1;
}
.btnAlteraFoto {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 22;
}
.btn_default_excluir {
	background-color: #e67979;
	height: 40px;
	border: none;
	font-size: 16px;
	font-weight: 700;
}
.btn_default_excluir:hover {
	background-color: #dc3545;
}
.btn_file {
	background-color: #e3e3e3;
	width: 100%;
	border: 1px solid #e3e3e3;
	font-size: 16px;
	color: #ffff;
	height: 40px;
	font-weight: 700;
}
.btn_file:hover {
	background-color: #e0a638;
}
</style>
