<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-center w-100"
      style="height: 250px"
      v-if="load"
    >
      <loader color="#e0a638" size="100" />
    </div>
    <transition name="vertical">
      <p
        v-if="ServicosEdit.length == 0 && !load"
        class="my-5 text-center w-100"
        style="font-size: 16px; color: #ffff"
      >
        Ainda não existem solicitações pendentes
      </p>
    </transition>
    <div
      class="col-md-12 element_div px-0"
      style="overflow-x: hidden; overflow-y: hidden"
    >
      <transition-group
        name="list-complete"
        tag="div"
        class="d-flex over-scroll"
      >
        <ValidationObserver
          class="col-md-5 itens list-complete-item"
          v-for="(item, index) in ServicosEdit"
          :key="index + 1"
          ref="form"
        >
          <div class="box py-4 px-4 mb-2">
            <topo
              :item.sync="item"
              :nome.sync="item.name"
              :link.sync="item.link"
              :type.sync="item.type"
              :value.sync="item.value"
              :secondValue.sync="item.secondValue"
              :cost.sync="item.cost"
              :category.sync="item.category"
              :tenantInformation="item.tenantInformation"
            />
            <linkVideo />
            <popUp :HasPopup.sync="item.HasPopup" />
            <aprova
              :IsEdit="true"
              :item="item"
              :tenant.sync="tenant"
              :unidades="unidades"
              :tenants="item.tenants"
              :index="index"
              @getService="getService"
            />
          </div>
        </ValidationObserver>
      </transition-group>
    </div>
  </div>
</template>

<script>
import topo from "./item/topoEdit";
import passo from "./item/passoApasso";
import unidades from "./item/unidades";
import aprova from "./item/aprova";
import linkVideo from "./item/linkVideo";
import popUp from "./item/popUp";
import HTTP from "@/api_system";
import loader from "@/components/utils/_Load";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    topo,
    passo,
    unidades,
    aprova,
    loader,
    linkVideo,
    popUp
  },
  data() {
    return {
      ServicosEdit: "",
      unidades: [],
      load: true,
      tenant: ""
    };
  },
  mounted() {
    this.getService();
    this.getUnidades();
  },
  methods: {
    ...mapActions(["getSolicitacao"]),
    getService() {
      this.disabled = true;
      this.load = true;
      HTTP.get(`services/app/ServiceEdit/GetAll`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.load = false;
          this.disabled = false;
          this.ServicosEdit = response.data.result;
          this.ServicosEdit.forEach(servico => {
            this.$store.state.servicos.servicosCategoria.forEach(cat => {
              if (cat.id == servico.categoryId) {
                servico.category = { id: cat.id, name: cat.name };
              }
            });
          });

          this.$nextTick(() => {
            this.scroll();
          });
        })
        .catch(error => {
          this.load = false;
          this.disabled = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    },
    scroll() {
      var elementW = $(".element_div").width();
      var valTotal = $(".itens").length;

      var w = $(".itens").width() * valTotal;
      var valMin = elementW - w - 30 * $(".itens").length;
      Draggable.create(".over-scroll", {
        type: "x",
        throwProps: true,
        edgeResistance: 0.9,
        maxDuration: 0,
        bounds: { maxX: 0, minX: valTotal > 2 ? valMin : 0 },
        snapX: [0, valMin]
      });
    },
    getUnidades() {
      this.load = true;
      HTTP.post("services/app/Tenant/ListOfTenants", "", {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.unidades = response.data.result.activeTenants;
        })
        .catch(error => {
          this.load = false;
        });
    }
  },
  computed: {
    ...mapGetters(["get_message"])
  },
  watch: {
    get_message: function() {
      if (this.get_message == 2) {
        this.getService();
        this.getUnidades();
        this.getSolicitacao();
      }
    }
  }
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  width: 100%;
  min-height: 400px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
.over-scroll {
  cursor: pointer !important;
}
</style>
