<template>
  <div class="solicitacao py-5">
    <div class="welcomeMobile">
        <router-link to="/" class="imgWelCont">
          <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Solicitações</p>
        </div>
    </div>
    <div class="container">
      <div class="col-md-12 mb-5">
        <div class="row mx-0">
          <div class="col-md-4" id="soliciPend">
            <p class="titulo_dash mb-0">Solicitações pendentes</p>
          </div>
          <div
            class="col-md-4 ml-auto d-flex justify-content-end tipo_solicitacao"
          >
            <p
              class="mb-0 mr-4"
              @click.prevent="trocaCp('solicitacao')"
              :class="cp == 'solicitacao' ? 'active' : ''"
            >
              Novos serviços ({{ totalSolicita.servicesLenght }})
            </p>

            <p
              class="mb-0 mr-4"
              @click.prevent="trocaCp('EditServicos')"
              :class="cp == 'EditServicos' ? 'active' : ''"
            >
              Edição de serviços ({{ totalSolicita.serviceEditLenght }})
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <component :is="cp" />
      </div>
    </div>
  </div>
</template>

<script>
import solicitacao from "@/components/solicitacao/adm/cpSolicitacao";
import EditServicos from "@/components/solicitacao/adm/cpEditarServico";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    solicitacao,
    EditServicos
  },
  data() {
    return {
      cp: "solicitacao"
    };
  },
  methods: {
    ...mapActions(["getSolicitacao"]),
    trocaCp(val) {
      this.cp = val;
    }
  },
  watch: {
    "totalSolicita.serviceEditLenght": function() {
      if (!this.totalSolicita.serviceEditLenght) {
        this.getSolicitacao();
      }
    },
    "totalSolicita.servicesLenght": function() {
      if (!this.totalSolicita.serviceEditLenght) {
        this.getSolicitacao();
      }
    }
  },
  computed: {
    ...mapGetters(["totalSolicita"])
  }
};
</script>

<style scoped>
.solicitacao {
  background-color: #2474b2;
}
.tipo_solicitacao p {
  font-weight: 700;
  font-size: 12px;
  color: #175d93;
  cursor: pointer;
}
.active {
  color: #ffffff !important;
}
@media screen and (min-width:768px){
  .welcomeMobile{
    display:none;
  }
}
@media screen and (max-width:767px){
  #soliciPend{
    margin-bottom: 4%;
  }
  .solicitacao{
    padding-top: 0!important;
  }
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 3%;
  }
  .imgWelCont{
    width: 49%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 26%;
  }
}
</style>
