<template>
  <div class="row my-3" id="buttonUnityCont">
    <div class="col-md-6">
      <div class="rejeita solicita mx-2">
        <button
          :disabled="disabled"
          class="mb-0 btn btn-outline-danger"
          @click.prevent="reject()"
        >
          <i v-if="!rejected" class="far fa-times-circle mr-1"></i>
          <span
            v-else
            class="spinner-border spinner-border-sm mb-1"
            role="status"
            aria-hidden="true"
          ></span>
          Rejeitar
        </button>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <div class="aceita solicita mx-2">
        <button
          :disabled="disabled"
          class="mb-0 btn btn-outline-warning"
          @click.prevent="aprove(item)"
        >
          Aceitar
          <i v-if="!aproved" class="far fa-check-circle ml-1"></i>
          <span
            v-else
            class="spinner-border spinner-border-sm mb-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import { mapActions } from "vuex";

export default {
  props: ["item", "tenant", "unidades", "tenants", "index", "IsEdit"],
  data() {
    return {
      aproved: false,
      rejected: false,
      disabled: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions(["getSolicitacao", "getServices"]),
    reject() {
      this.rejected = this.disabled = true;
      if (this.IsEdit) {
        HTTP.post(
          `services/app/ServiceEdit/ReproveServiceEdit?id=${this.item.id}`,
          "",
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        )
          .then(response => {
            this.$emit("getService");
            this.getSolicitacao();
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Solicitação negada`
            });
            this.rejected = this.disabled = false;
          })
          .catch(error => {
            this.rejected = this.disabled = false;
          });
      } else {
        HTTP.post(`services/app/Service/RejectService?id=${this.item.id}`, "", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(response => {
            this.$emit("getService");
            this.getSolicitacao();
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Solicitação negada`
            });
            this.rejected = this.disabled = false;
          })
          .catch(error => {
            this.rejected = this.disabled = false;
          });
      }
    },
    aprove(item) {
      var value = item.value;
      var secondValue = item.secondValue;
      if (value.toString().includes(",")) {
        value = item.value.replace(",", ".");
      }
      if (secondValue.toString().includes(",")) {
        secondValue = item.secondValue.replace(",", ".");
      }
      item.value = parseFloat(value);
      item.secondValue = parseFloat(secondValue);

      if (item.categoryId == 0) {
        item.categoryId = 1;
      }
      if (this.IsEdit) {
        this.$parent.validate().then(success => {
          if (success) {
            this.aproved = this.disabled = true;
            var json = {
              Id: item.id,
              ServiceId: item.serviceId,
              Link: item.link,
              Name: item.name,
              CategoryId: item.categoryId,
              VideoUrl: item.videoUrl,
              Value: item.value,
              SecondValue: item.secondValue,
              Cost: 0,
              StatusService: item.statusService,
              HasPopup: item.hasPopup
            };
            HTTP.post(`services/app/ServiceEdit/AproverServiceEdit`, json, {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getterToken}`
              }
            })
              .then(response => {
                this.$emit("getService");
                this.getSolicitacao();
                this.getServices();
                this.$notify({
                  group: "erros",
                  type: "sucess",
                  text: `<i class="icon ion-close-circled"></i>Solicitação aceita`
                });
                this.aproved = this.disabled = false;
              })
              .catch(error => {
                this.aproved = this.disabled = false;
                this.$notify({
                  group: "erros",
                  type: "error",
                  text: `<i class="icon ion-close-circled"></i>Ops algo deu errado!`
                });
              });
          }
        });
      } else {
        this.aproved = this.disabled = true;
        var array = [];
        this.unidades.forEach(element => {
          this.tenant.unidade.forEach(item => {
            if (
              `${element.city}|${element.state}|${element.reference}` == item
            ) {
              array.push({
                tenantId: element.id,
                tenanancyName: element.tenancyName
              });
            }
          });
        });
        this.item.tenants = array;
        const payLoad = {
          categoryId: this.item.categoryId,
          cost: this.item.cost,
          id: this.item.id,
          instructions: this.item.instructions,
          link: this.item.link,
          name: this.item.name,
          secondValue: this.item.secondValue,
          tags: this.item.tags,
          tenantInformation: this.item.tenantInformation,
          tenantsAndValues: this.item.tenants.map(s => ({
            tenantId: s.tenantId,
            tenanancyName: s.tenanancyName,
            serviceId: this.item.id
          })),
          type: this.item.type,
          value: this.item.value,
          videoUrl: this.item.videoUrl
        };

        HTTP.put(`services/app/Service/UpdateService`, payLoad, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(response => {
            this.$emit("getService");
            this.$store.dispatch("getSolicitacao");
            this.getServices();
            this.$emit("removeTenantFilter", this.tenant);
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>Solicitação aceita`
            });
            this.aproved = this.disabled = false;
          })
          .catch(error => {
            this.aproved = this.disabled = false;
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Ops algo deu errado!`
            });
          });
      }
    }
  }
};
</script>

<style scoped>
/* .rejeita {
  color: #e67979;
}
.aceita {
  color: #2474b2;
} */
p {
  font-size: 20px;
}
.btn-outline-warning {
  color: #e0a638;
  border-color: #e0a638;
}
.btn-outline-warning:hover {
  background-color: #e0a638;
  color: white;
}
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #e0a638;
  color: white;
}
@media screen and (max-width:767px){
  #buttonUnityCont{
    display: flex;
    flex-direction: row;
    flex-wrap: unset;
  }
}
</style>
