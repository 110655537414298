<template>
  <div class="row">
    <div class="col-md-6">
      <ValidationProvider
        class="col-md-12 px-0 mb-2"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Serviço"
        tag="div"
      >
        <label class="label_default" :class="classes">Serviço</label>
        <input
          type="text"
          class="input_default form-control"
          :class="classes"
          :value="nome"
          @input="$emit('update:nome', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <!-- <h4 class="mb-3">{{item.name}}</h4> -->
      <ValidationProvider
        class="col-md-12 px-0 mb-3"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Categoria"
        tag="div"
      >
        <label class="label_default" :class="classes">Categoria</label>
        <select
          class="input_default form-control"
          @change="emitCategory(category)"
          v-model="category"
          :class="classes"
        >
          <option
            :selected="item.id == category.id"
            :value="item"
            v-for="(item, index) in categoria"
            :key="index"
          >
            {{ item.name }}
          </option>
        </select>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <p v-if="tenantInformation != null" class="mb-0">
        {{ getEstados(tenantInformation.state) }} / {{ tenantInformation.city }}
        <br />
        <strong>{{ tenantInformation.tenantName }}</strong>
        <br />
        <span>{{ tenantInformation.franchiseName }}</span>
      </p>
    </div>
    <div class="col-md-6">
      <ValidationProvider
        class="row align-items-center mb-1"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Valor"
        tag="div"
      >
        <div class="col-md-6 text-right ml-auto">
          <label class="label_default mb-0" :class="classes">Valor</label>
        </div>
        <div class="col-md-5 pl-0">
          <input
            type="text"
            class="input_default form-control"
            v-model="value"
            :class="classes"
            @change="setValue($event.target.value)"
          />
        </div>
      </ValidationProvider>
      <ValidationProvider
        class="row align-items-center mb-1"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Secundário"
        tag="div"
      >
        <div class="col-md-6 text-right ml-auto">
          <label class="label_default mb-0" :class="classes">Secundário</label>
        </div>
        <div class="col-md-5 pl-0">
          <input
            type="text"
            class="input_default form-control"
            :class="classes"
            v-model="secondValue"
            @change="setSecundaryValue($event.target.value)"
          />
        </div>
      </ValidationProvider>
    </div>
    <div class="col-md-12 my-3">
      <div class="row">
        <ValidationProvider
          class="col-md-6"
          v-slot="{ errors, ariaMsg, classes }"
          rules="required"
          name="Tipo"
        >
          <label for class="label_default" :class="classes">Tipo</label>
          <select
            class="input_default form-control"
            :value="type"
            :class="classes"
            @change="$emit('update:type', $event.target.value)"
          >
            <option
              :value="item.value"
              v-for="(item, index) in listTypeService"
              :key="index"
              >{{ item.name }}</option
            >
          </select>
        </ValidationProvider>
        <validationProvider
          class="col-md-6"
          v-slot="{ errors, ariaMsg, classes }"
          name="Link"
          :rules="
            type == 1
              ? {
                  required: true,
                  regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
                }
              : ''
          "
        >
          <label
            for
            class="label_default"
            :class="type == 1 ? classes : ''"
            :style="type == 2 ? 'color: #868686!important;' : ''"
            >Link</label
          >
          <input
            type="text"
            :disabled="type == 2 ? true : false"
            class="input_default"
            :class="type == 1 ? classes : 'disabled'"
            :value="link"
            @input="$emit('update:link', $event.target.value)"
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </validationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "item",
    "category",
    "tenantInformation",
    "nome",
    "link",
    "type",
    "value",
    "secondValue"
  ],
  data() {
    return {
      valor: 0,
      custo: 0,
      secundario: 0,
      uf: [
        { nome: "Acre", sigla: "AC" },
        { nome: "Alagoas", sigla: "AL" },
        { nome: "Amapá", sigla: "AP" },
        { nome: "Amazonas", sigla: "AM" },
        { nome: "Bahia", sigla: "BA" },
        { nome: "Ceará", sigla: "CE" },
        { nome: "Distrito Federal", sigla: "DF" },
        { nome: "Espírito Santo", sigla: "ES" },
        { nome: "Goiás", sigla: "GO" },
        { nome: "Maranhão", sigla: "MA" },
        { nome: "Mato Grosso", sigla: "MT" },
        { nome: "Mato Grosso do Sul", sigla: "MS" },
        { nome: "Minas Gerais", sigla: "MG" },
        { nome: "Pará", sigla: "PA" },
        { nome: "Paraíba", sigla: "PB" },
        { nome: "Paraná", sigla: "PR" },
        { nome: "Pernambuco", sigla: "PE" },
        { nome: "Piauí", sigla: "PI" },
        { nome: "Rio de Janeiro", sigla: "RJ" },
        { nome: "Rio Grande do Norte", sigla: "RN" },
        { nome: "Rio Grande do Sul", sigla: "RS" },
        { nome: "Rondônia", sigla: "RO" },
        { nome: "Roraima", sigla: "RR" },
        { nome: "Santa Catarina", sigla: "SC" },
        { nome: "São Paulo", sigla: "SP" },
        { nome: "Sergipe", sigla: "SE" },
        { nome: "Tocantins", sigla: "TO" }
      ],
      itemCategoria: []
    };
  },
  mounted() {
    this.$emit("update:type", 1);
    this.item.value = this.item.value.toString().replace(".", ",");
    this.item.secondValue = this.item.secondValue.toString().replace(".", ",");
  },
  methods: {
    getEstados(e) {
      var nome;
      this.uf.forEach(item => {
        if (e.toLowerCase() == item.sigla.toLowerCase()) {
          nome = item.nome;
        }
      });
      return nome;
    },
    emitCategory(val) {
      this.$emit("update:category", val);
    },
    setValue(value) {
      this.$emit(`update:value`, value);
    },
    setSecundaryValue(value) {
      this.$emit(`update:secondValue`, value);
    }
  },
  watch: {
    valor() {
      this.item.goalsNet.forEach(element => {
        this.item.goalsVolume.forEach(item => {
          if (item.port == element.port) {
            element.value = item.value * this.item.value;
          }
        });
      });
    },
    custo() {
      this.item.goalsCost.forEach(element => {
        this.item.goalsVolume.forEach(item => {
          if (item.port == element.port) {
            element.value = item.value * this.item.cost;
          }
        });
      });
    }
  },
  computed: {
    categoria() {
      var array = [];
      this.$store.state.servicos.servicosCategoria.forEach(item => {
        array.push({ id: item.id, name: item.name });
      });
      return array;
    },
    listTypeService() {
      return this.$store.state.servicos.servicesType;
    }
  }
};
</script>

<style scoped>
h4 {
  font-size: 16px;
  color: #2474b2 !important;
  line-height: 15px;
}
h4 span {
  font-size: 11px;
  color: #868686 !important;
}
p {
  color: #2474b2 !important;
  font-size: 11px;
  line-height: 15px;
}
p span {
  color: #868686 !important;
}
.label_default {
  color: #2474b2 !important;
  /* font-size: 10px; */
}
.input_default {
  height: 30px;
  color: #2474b2 !important;
}
.label_default.failed {
  color: #dc3545 !important;
}
.link {
  color: #f38235 !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
